import { Modal, Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { therapistColumns } from '../../Consts/TableColumns';
import { GetSpeechLanguagePathologists } from '../../Services';

const SpeechTherapistTable = ({visibility, setVisibility, handleSubmit}) => {
    const [data, setData] = useState([]);
    const [highlighted, setHighlighted] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(visibility){
            GetSpeechLanguagePathologists().then((res) => {
                setData(res?.data);
                setHighlighted(res?.data[0]);
            }).catch(() => {
                message.error('Therapists not retrieved');
            }).finally(() => {
                setLoading(false);
            })
        }
    }, [visibility]);

    const handleRowOnClick = record => {
        setHighlighted(record);
    }

    const handleOnSubmit = () => {
        handleSubmit(highlighted);
        setVisibility(false);
    }

    return(
        <Modal
            title="Speech Therapists"
            open={visibility}
            onCancel={() => setVisibility(false)}
            onOk={() => handleOnSubmit()}
        >
            <Table 
                columns={therapistColumns}
                dataSource={data}
                pagination={{ hideOnSinglePage: true }}
                loading={loading}
                rowKey={(record) => record.id}
                rowClassName={record => (highlighted === record ? 'ant-table-row-selected' : '')}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: () => handleRowOnClick(record)
                    }
                }}
            />
        </Modal>
    )
}

export default SpeechTherapistTable;
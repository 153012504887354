import React from 'react';

export const FormDescription = ({description}) => {
    if (description) {
        return (
            <span className="ant-tag-custom">
                <span className="ant-tag-blue-custom">{description}</span>
            </span>
        );
    } else {
        return null;
    }
}
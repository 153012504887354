import React, {useState, useEffect} from "react";
import { Layout, ConfigProvider, Empty, Grid, Menu } from "antd";
import moment from "moment/moment";
import { withRouter} from 'react-router';
import { AppSettingsContext, ClientContext, FormContext, SiderCollapsedContext } from "../Contexts";
import PediplayHeader from "./PediplayHeader";
import { GetAPIURL } from "../URL";
import SiderMenu from "./SiderMenu";
import AppRoutes from "../Routes";

const { Content, Sider, Footer} = Layout;
const { useBreakpoint } = Grid;

const PediPlayLayout = ({signOut, user}) => {
        const [collapsed, setCollapsed] = useState(false);
        const [formContext, setFormContext] = useState({});
        const [selectedClient, setSelectedClient] = useState();
        const [theme, setTheme] = useState('dark');
        const [URL, setURL] = useState(GetAPIURL());

        let screen = useBreakpoint();

        return (
            <ConfigProvider>
                <Layout style={{ minHeight: '100vh', background: '#001529' }}>
                  <AppSettingsContext.Provider 
                    value={{
                        URL,
                        setURL
                    }}
                  >
                    <FormContext.Provider
                        value={{
                            formContext,
                            setFormContext
                        }}
                    >
                        <ClientContext.Provider
                            value={{ selectedClient, setSelectedClient }}
                        >
                            <SiderCollapsedContext.Provider
                                value={{ collapsed, setCollapsed }}
                            >
                                <Sider
                                    trigger={null}
                                    theme={theme}
                                    collapsible
                                    onCollapse={(value) => setCollapsed(value)}
                                    collapsed={collapsed}
                                    width={257}
                                    style={{
                                        //overflow: 'auto',
                                        //position: 'fixed',
                                        top: '64px',
                                        //left: 0,
                                        //zIndex: 9,
                                        //boxShadow: 
                                        //    '2px 0 8px 0 rgba(29,35,41,.05',
                                    }}
                                    collapsedWidth={screen.xs ? '0' : '80px'}
                                >
                                    <div className="demo-logo-vertical" />
                                    <SiderMenu />
                                </Sider>
                                <Layout>
                                    <PediplayHeader
                                        setCollapsed={setCollapsed}
                                        collapsed={collapsed}
                                        signOut={signOut}
                                    />
                                    <Content
                                        style={{
                                            margin: '16px 0',
                                        }}
                                    >
                                        <AppRoutes/>
                                    </Content>
                                    <Footer style={{ textAlign: 'center' }}>
                                        ©2023 Briar Consulting, LLC
                                    </Footer>
                                </Layout>
                            </SiderCollapsedContext.Provider>
                        </ClientContext.Provider>
                    </FormContext.Provider>
                  </AppSettingsContext.Provider>  
                </Layout>
            </ConfigProvider>
        );
    };

export default PediPlayLayout;
import React, { useState, useEffect } from 'react';
import { Layout, Breadcrumb, Card, Grid} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';


const { useBreakpoint } = Grid;
const { Header, Footer, Sider, Content } = Layout;

const PediPlayPageHeader = ({ title, props, content, footer, extra, subTitle, loading}) => {
    const screenSize = useBreakpoint();

    return ( 
        <Card 
            size="small"
            className="efPageHeader"
            bordered={false}
            style={{
                width: '100%',
                transition: 'all 0.3s ease 0s',
            }}
        >
            {screenSize && !screenSize.xs ? (
                <>
                    <PageHeader
                        title={title}
                        subTitle={subTitle}
                        footer={footer}
                        extra={extra}
                        onBack={() => window.history.back()}
                    >
                        {content}
                    </PageHeader>
                </>
            ) : (
                <PageHeader 
                    title={title}
                    subTitle={screenSize && !screenSize.xs ? subTitle : null}
                    footer={footer}
                    extra={<span style={{ width: '100%', textAlign: 'right' }}>{extra}</span>}
                    onBack={() => window.history.back()}
                >
                    {content}
                </PageHeader>
            )}
        </Card>
     );
}
 
export default PediPlayPageHeader;
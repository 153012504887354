import { Button, Form, Modal, Popconfirm, Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import { allFormKeys } from './FormKeys';
import SequentialMessageNotifier from '../../Shared/Functions/SequentialMessageNotifier';
import { CreateTherapist, EditTherapist } from '../../Shared/Services';
import PersonalAndAddress from './Steps/PersonalAndAddress';
import ServiceAvailability from './Steps/ServiceAvailability';
import Specialities from './Steps/Specialties';
import { brownOptionValues, hancockOptionValues, johnsonOptionValues, lawrenceOptionValues, marionOptionValues, monroeOptionValues, morganOptionValues, shelbyOptionValues } from './Steps/CheckboxOptions';

const {Step} = Steps;

const TherapistForm = ({
    formType,
    setFormType,
    visibility,
    setVisibility,
    highlighted,
    handleAfterFormSubmit
}) => {
    const [form] = Form.useForm();
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [brownCheckedList, setBrownCheckedList] = useState();
    const [hancockCheckedList, setHancockCheckedList] = useState();
    const [johnsonCheckedList, setJohnsonCheckedList] = useState();
    const [lawrenceCheckedList, setLawrenceCheckedList] = useState();
    const [marionCheckedList, setMarionCheckedList] = useState();
    const [monroeCheckedList, setMonroeCheckedList] = useState();
    const [morganCheckedList, setMorganCheckedList] = useState();
    const [shelbyCheckedList, setShelbyCheckedList] = useState();

    console.log(highlighted);

    //This series of functions scan through the highlighted record on edit, and sets various checkboxes true or false.
    const handleBrownCounty = (obj) => {
        const resultArray = [];

        for (const key of brownOptionValues) {
            if (obj[key] === true){
                resultArray.push(key);
            }
        }

        setBrownCheckedList(resultArray)
    };

    const handleHancockCounty = (obj) => {
        const resultArray = [];

        for (const key of hancockOptionValues) {
            if (obj[key] === true){
                resultArray.push(key);
            }
        }

        setHancockCheckedList(resultArray)
    };

    const handleJohnsonCounty = (obj) => {
        const resultArray = [];

        for (const key of johnsonOptionValues) {
            if (obj[key] === true){
                resultArray.push(key);
            }
        }

        setJohnsonCheckedList(resultArray)
    };

    const handleLawrenceCounty = (obj) => {
        const resultArray = [];

        for (const key of lawrenceOptionValues) {
            if (obj[key] === true){
                resultArray.push(key);
            }
        }

        setLawrenceCheckedList(resultArray)
    };

    const handleMarionCounty = (obj) => {
        const resultArray = [];

        for (const key of marionOptionValues) {
            if (obj[key] === true){
                resultArray.push(key);
            }
        }

        setMarionCheckedList(resultArray)
    };

    const handleMonroeCounty = (obj) => {
        const resultArray = [];

        for (const key of monroeOptionValues) {
            if (obj[key] === true){
                resultArray.push(key);
            }
        }

        setMonroeCheckedList(resultArray)
    };

    const handleMorganCounty = (obj) => {
        const resultArray = [];

        for (const key of morganOptionValues) {
            if (obj[key] === true){
                resultArray.push(key);
            }
        }

        setMorganCheckedList(resultArray)
    };

    const handleShelbyCounty = (obj) => {
        const resultArray = [];

        for (const key of shelbyOptionValues) {
            if (obj[key] === true){
                resultArray.push(key);
            }
        }

        setShelbyCheckedList(resultArray)
    };

    useEffect(() => {
        if(formType === 'Edit') {
            form.setFieldsValue({
                active: highlighted.active,
                otherAgency: highlighted.otherAgency,
                discipline: highlighted.discipline,
                firstName: highlighted.firstName,
                preferredName: highlighted.preferredName,
                lastName: highlighted.lastName,
                emailAddress: highlighted.emailAddress,
                phoneNumber: highlighted.phoneNumber,
                address: highlighted.address,
                address2: highlighted.address2,
                city: highlighted.city,
                state: highlighted.state,
                zipcode: highlighted.zipcode,
                country: highlighted.country,
                virtual: highlighted.virtual,
                comments: highlighted.comments,
                mentorId: highlighted.mentorId,
                supervisorId: highlighted.supervisorId,
                buremese: highlighted.buremese,
                chin: highlighted.chin,
                falamChin: highlighted.falamChin,
                hakhaChin: highlighted.hakhaChin,
                matuChin: highlighted.matuChin,
                tedimChin: highlighted.tedimChin,
                zanniatChin: highlighted.zanniatChin,
                zophieChin: highlighted.zophieChin,
                crioulo: highlighted.crioulo,
                filipino: highlighted.filipino,
                french: highlighted.french,
                frenchCajun: highlighted.frenchCajun,
                frenchCanadian: highlighted.frenchCanadian,
                haitainCreole: highlighted.haitainCreole,
                german: highlighted.german,
                punjabi: highlighted.punjabi,
                spanish: highlighted.spanish,
                vietnamese: highlighted.vietnamese,
                somali: highlighted.somali,
                swahili: highlighted.swahili,
                oralMotorFeeding: highlighted.oralMotorFeeding,
                sensoryBasedFeeding: highlighted.sensoryBasedFeeding,
                gTubeToOral: highlighted.gTubeToOral,
                foodAversion: highlighted.foodAversion,
                motorTransitSwallowingDysfunction: highlighted.motorTransitSwallowingDysfunction,
                behavior: highlighted.behavior,
                specifyBehavior: highlighted.specifyBehavior,
                als: highlighted.als,
                signingExactEnglish: highlighted.signingExactEnglish,
                handlingNDT: highlighted.handlingNDT,
                gTubeComfortable: highlighted.gTubeComfortable,
                ventComfortable: highlighted.ventComfortable,
                passyMuirValve: highlighted.passyMuirValve,
                floortime: highlighted.floortime,
                infantMentalHealthCert: highlighted.infantMentalHealthCert,
                fgrbiCohort: highlighted.fgrbiCohort,
                abaCert: highlighted.abaCert,
                kinesiotapingExp: highlighted.kinesiotapingExp,
                kinesiotapingCert: highlighted.kinesiotapingCert,
                aACComfort: highlighted.aACComfort,
                aACExperience: highlighted.aACExperience,
                aACCertification: highlighted.aACCertification,
                visualScheduleExperience: highlighted.visualScheduleExperience,
                pecs: highlighted.pecs,
                hannen: highlighted.hannen,
                assistativeTechnology: highlighted.assistativeTechnology,
                orthoticsUse: highlighted.orthoticsUse,
                orthoticsAdjustment: highlighted.orthoticsAdjustment,
                wheelchairSupport: highlighted.wheelchairSupport,
                wheelchairTraining: highlighted.wheelchairTraining,
                wheelchairFitting: highlighted.wheelchairFitting,
                aquaticTherapy: highlighted.aquaticTherapy,
                hippoTherapy: highlighted.hippoTherapy,
                autismExperience: highlighted.autismExperience,
                splinting: highlighted.splinting,
                weightedVestFitting: highlighted.weightedVestFitting,
                primitiveReflexIntegration: highlighted.primitiveReflexIntegration,
                additionalExpertise: highlighted.additionalExpertise,
                additionalExpertiseMemo: highlighted.additionalExpertiseMemo,
                picturesLink: highlighted.picturesLink
            });
            handleBrownCounty(highlighted);
            handleHancockCounty(highlighted);
            handleJohnsonCounty(highlighted);
            handleLawrenceCounty(highlighted);
            handleMarionCounty(highlighted);
            handleMonroeCounty(highlighted);
            handleMorganCounty(highlighted);
            handleShelbyCounty(highlighted);
        } else {
            form.setFieldsValue({
                active: true,
                otherAgency: false,
                falamChin: false,
                hakhaChin: false,
                matuChin: false,
                tedimChin: false,
                zanniatChin: false,
                zophieChin: false,
                crioulo: false,
                filipino: false,
                french: false,
                frenchCajun: false,
                frenchCanadian: false,
                haitainCreole: false,
                german: false,
                punjabi: false,
                spanish: false,
                vietnamese: false,
                somali: false,
                swahili: false,
                oralMotorFeeding: false,
                sensoryBasedFeeding: false,
                gTubeToOral: false,
                foodAversion: false,
                motorTransitSwallowingDysfunction: false,
                behavior: false,
                als: false,
                signingExactEnglish: false,
                handlingNDT:false,
                gTubeComfortable: false,
                ventComfortable: false,
                passyMuirValve: false,
                floortime: false,
                infantMentalHealthCert: false,
                fgrbiCohort: false,
                abaCert: false,
                kinesiotapingExp: false,
                kinesiotapingCert: false,
                aACComfort: false,
                aACExperience: false,
                aACCertification: false,
                visualScheduleExperience: false,
                pecs: false,
                hannen: false,
                assistativeTechnology: false,
                orthoticsUse: false,
                orthoticsAdjustment: false,
                wheelchairSupport: false,
                wheelchairTraining: false,
                wheelchairFitting: false,
                aquaticTherapy: false,
                hippoTherapy: false,
                autismExperience: false,
                splinting: false,
                weightedVestFitting: false,
                primitiveReflexIntegration: false,
                additionalExpertise: false
            })
        }
    }, [visibility]);


    const onClose = () => {
        form.resetFields();
        setVisibility(false);
        setFormType();
        setPage(0);
    }

    const putRequest = () => {
        form.validateFields(allFormKeys).then(values => {
            SequentialMessageNotifier(formType);
            setLoading(true);
            values = {
                // ...highlighted,
                ...values,
                mentorId: values.mentorId ? values.mentorId : null,
                supervisorId: values.supervisorId ? values.supervisorId : null,
                discipline: values.discipline ? parseInt(values.discipline) : 0,
                virtual: values.virtual ? parseInt(values.virtual) : 0
            };
            brownCheckedList.forEach((record) => {
                values[record] = true;
            });
            hancockCheckedList.forEach((record) => {
                values[record] = true;
            });
            johnsonCheckedList.forEach((record) => {
                values[record] = true;
            });
            lawrenceCheckedList.forEach((record) => {
                values[record] = true;
            });
            marionCheckedList.forEach((record) => {
                values[record] = true;
            });
            monroeCheckedList.forEach((record) => {
                values[record] = true;
            });
            morganCheckedList.forEach((record) => {
                values[record] = true;
            });
            shelbyCheckedList.forEach((record) => {
                values[record] = true;
            });
            EditTherapist(highlighted.id, values)
                .then(res => {
                    if ( res && res.data ) {
                        SequentialMessageNotifier(formType, 'success');
                        handleAfterFormSubmit(res?.data);
                        onClose();
                    }
                })
                .catch(error => {
                    if(error.response) {
                        if(error.response.data.ModelState) {
                            let obj = error.response.data.ModelState;
                            SequentialMessageNotifier(formType, 'error', obj);
                        } else if (error.response.data.Message) {
                            SequentialMessageNotifier(formType, 'error', error.response.data.Message);
                        }
                    }
                })
                .finally(() => {
                    setLoading(false)
                });
        });
    };

    const postRequest = () => {
        form.validateFields(allFormKeys).then(values => {
            SequentialMessageNotifier(formType);
            setLoading(true);
            values = {
                ...values,
                active: true,
                mentorId: values.mentorId ? values.mentorId : null,
                supervisorId: values.supervisorId ? values.supervisorId : null,
                discipline: values.discipline ? parseInt(values.discipline) : 0,
                virtual: values.virtual ? parseInt(values.virtual) : 0
            };
            brownCheckedList.forEach((record) => {
                values[record] = true;
            });
            hancockCheckedList.forEach((record) => {
                values[record] = true;
            });
            johnsonCheckedList.forEach((record) => {
                values[record] = true;
            });
            lawrenceCheckedList.forEach((record) => {
                values[record] = true;
            });
            marionCheckedList.forEach((record) => {
                values[record] = true;
            });
            monroeCheckedList.forEach((record) => {
                values[record] = true;
            });
            morganCheckedList.forEach((record) => {
                values[record] = true;
            });
            shelbyCheckedList.forEach((record) => {
                values[record] = true;
            });
            CreateTherapist(values)
                .then(res => {
                    if ( res && res.data ) {
                        SequentialMessageNotifier(formType, 'success');
                        handleAfterFormSubmit(res?.data);
                        onClose();
                    }
                })
                .catch(error => {
                    if(error.response) {
                        if(error.response.data.ModelState) {
                            let obj = error.response.data.ModelState;
                            SequentialMessageNotifier(formType, 'error', obj);
                        } else if (error.response.data.Message) {
                            SequentialMessageNotifier(formType, 'error', error.response.data.Message);
                        }
                    }
                })
                .finally(() => {
                    setLoading(false)
                });
        }).catch((e) => {
            console.log(e)
        });
    }

    const onSubmit = () => {
        if(formType === 'Create'){
            postRequest();
        } else {
            putRequest();
        }
    }

    const prev = () => {
        setPage(page - 1);
    }

    const next = () => {
        form.validateFields().then(() => {
            setPage(page + 1)
        })
    }

    const steps = [
        {
            title: 'Personal and Address',
            content: (
                <PersonalAndAddress 
                    form={form}
                    formType={formType}
                />
            ),
        },
        {
            title: 'Service Availability',
            content: (
                <ServiceAvailability 
                form={form}
                brownCheckedList={brownCheckedList}
                setBrownCheckedList={setBrownCheckedList}
                hancockCheckedList={hancockCheckedList}
                setHancockCheckedList={setHancockCheckedList}
                johnsonCheckedList={johnsonCheckedList}
                setJohnsonCheckedList={setJohnsonCheckedList}
                lawrenceCheckedList={lawrenceCheckedList}
                setLawrenceCheckedList={setLawrenceCheckedList}
                marionCheckedList={marionCheckedList}
                setMarionCheckedList={setMarionCheckedList}
                monroeCheckedList={monroeCheckedList}
                setMonroeCheckedList={setMonroeCheckedList}
                morganCheckedList={morganCheckedList}
                setMorganCheckedList={setMorganCheckedList}
                shelbyCheckedList={shelbyCheckedList}
                setShelbyCheckedList={setShelbyCheckedList}
                />
            ),
        },
        {
            title: 'Specialties',
            content: (
                <Specialities form={form}/>
            ),
        },
    ]

    return (
        <Modal
            title={
                <Steps
                    style={{ borderRadius:'10px'}}
                    current={page}
                    onChange={stepNum => setPage(stepNum)}
                    direction="horizontal"
                >
                    {steps.map(i => (
                        <Step className="step-title" key={i.title} title={i.title}/>
                    ))}
                </Steps>
            }
            onCancel={() => onClose()}
            open={visibility}
            width="50%"
            onOk={() => onSubmit()}
            footer={
                <>
                    <Popconfirm title="Are you sure?" placement="bottomRight" onConfirm={() => onClose()}>
                        <Button loading={loading} type="primary" ghost danger>
                            Cancel
                        </Button>
                    </Popconfirm>
                    <Button
                        type="primary"
                        loading={loading}
                        ghost
                        onClick={() => prev()}
                        style={{
                            display:
                                page >= 1 ? '' : 'none',
                        }}
                    >
                        Previous
                    </Button>
                    <Button
                        type="primary"
                        loading={loading}
                        ghost
                        onClick={() => next()}
                        style={{
                            display:
                                page === steps.length ? 'none' : '',
                        }}
                    >
                        Next
                    </Button>
                    <Button
                        type="primary"
                        loading={loading}
                        onClick={() => onSubmit()}
                    >
                        Submit
                    </Button>
                </>
            }
        >
            <Form.Provider>
                <div>
                    {steps[page]?.content}
                </div>
            </Form.Provider>
        </Modal>
    )
}

export default TherapistForm;
import React, { useState, useEffect } from 'react';
import { GetTherapists } from '../Shared/Services';
import { Button, Col, Row, Tooltip } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { iconSize } from '../Shared/UI/jsx';
import TherapistTable from './TherapistTable';
import TherapistForm from './Form';
import PediPlayPageHeader from '../Shared/UI/PediplayPageHeader'


const Therapists = props => {
    const [data, setData] = useState();
    const [highlighted, setHighlighted] = useState()
    const [loading, setLoading] = useState(true);
    const [formType, setFormType] = useState();
    const [visibility, setVisibility] = useState(false);

    useEffect(() => {
        GetTherapists()
            .then((res) => {
                setData(res?.data);
                setHighlighted(res?.data[0]);
        })
        .finally(() => {
            setLoading(false);
        })
    }, []);

    const handleEdit = () => {
        setFormType('Edit');
        setVisibility(true);
    }

    const handleCreate = () => {
        setFormType('Create');
        setVisibility(true);
    }

    const handleAfterFormSubmit = record => {
        setLoading(true);
        setData([record]);
        setHighlighted(record);
        setLoading(false);
    }

    const handleRowOnClick = record => {
        setHighlighted(record);
    }

    const extra = (
        <div style={{ textAlign: 'end' }}>
            <Tooltip title="Add" placement="top">
                <Button
                    type="link"
                    icon={<PlusOutlined style={iconSize} />}
                    onClick={() => handleCreate()}
                />
            </Tooltip>
            <Tooltip title="Edit" placement="top">
                <Button 
                    type="link"
                    icon={<EditOutlined style={iconSize} />}
                    onClick={() => handleEdit()}
                />
            </Tooltip>
            <TherapistForm 
                formType={formType}
                setFormType={setFormType}
                visibility={visibility}
                setVisibility={setVisibility}
                highlighted={highlighted}
                handleAfterFormSubmit={handleAfterFormSubmit}
            />
        </div>
    );

    return (
        <div>
            <Row gutter={[8,8]}>
                <Col span={24}>
                <PediPlayPageHeader
                    title={'Therapists'}
                    props={props}
                    extra={extra}
                    loading={loading}
                />
                </Col>
                <Col span={24}>
                    <TherapistTable loading={loading} highlighted={highlighted} therapists={data} handleRowOnClick={handleRowOnClick}/>
                </Col>
            </Row>
        </div>
    );

}

export default Therapists;
import SequentialMessageNotifier from "./SequentialMessageNotifier";

export const reusableSubmitValidator = (formType, postRequest, putRequest, form) => {
    if (formType === 'create') {
        return form
            .validateFields()
            .then(validateFields => {
                postRequest(validateFields);
            })
            .catch(error => {
                if(error.response) {
                    if(error.response.data.ModelState) {
                        let obj = error.response.data.ModelState;
                        SequentialMessageNotifier(formType, 'error', obj);
                    } else if (error.response.data.Message) {
                        SequentialMessageNotifier(formType, 'error', error.response.data.Message);
                    }
                }
            });
    } else if (formType === 'edit') {
        return form
            .validateFields()
            .then(validateFields => {
                putRequest(validateFields)
            })
            .catch(error => {
                if(error.response) {
                    if(error.response.data.ModelState) {
                        let obj = error.response.data.ModelState;
                        SequentialMessageNotifier(formType, 'error', obj);
                    } else if (error.response.data.Message) {
                        SequentialMessageNotifier(formType, 'error', error.response.data.Message);
                    }
                }
            });
    }
}

export const reusableSubmitValidatorWithKeys = (createFormKeys, editFormKeys, formType, postRequest, putRequest, form) => {
    if (formType === 'create') {
        return form
            .validateFields(createFormKeys)
            .then(validateFields => {
                postRequest(validateFields);
            })
            .catch(error => {
                if(error.response) {
                    if(error.response.data.ModelState) {
                        let obj = error.response.data.ModelState;
                        SequentialMessageNotifier(formType, 'error', obj);
                    } else if (error.response.data.Message) {
                        SequentialMessageNotifier(formType, 'error', error.response.data.Message);
                    }
                }
            });
    } else if (formType === 'edit') {
        return form
            .validateFields(editFormKeys)
            .then(validateFields => {
                putRequest(validateFields)
            })
            .catch(error => {
                if(error.response) {
                    if(error.response.data.ModelState) {
                        let obj = error.response.data.ModelState;
                        SequentialMessageNotifier(formType, 'error', obj);
                    } else if (error.response.data.Message) {
                        SequentialMessageNotifier(formType, 'error', error.response.data.Message);
                    }
                }
            });
    }
}

export const handleSPOE = (value) => {
    if(value === 0){
        return 'J'
    } else {
        return 'G'
    }
}

export const handleDiscipline = (value) => {
    switch(value){
        case 0:
            return 'None';
        case 1:
            return 'Physical Therapist';
        case 2:
            return 'Physical Therapist Assistant';
        case 3:
            return 'Occupational Therapist';
        case 4:
            return 'Certified Occupational Therapist';
        case 5:
            return 'Developmental Therapist';
        case 6:
            return 'Developmental Therapy Assistant';
        case 7:
            return 'Speech-Language Pathologist';
        case 8:
            return 'Speech-Language Pathologist Clinical Fellowship';
        case 9:
            return 'Registered Nurse';
        case 10:
            return 'Social Worker';
        case 11:
            return 'Behavioralist';
        case 12:
            return 'Psychologist';
        case 13:
            return 'Student';
    }
}
import { message } from "antd";
import React from 'react';

const SequentialMessageNotifier = (formType, progress, errorObj) => {
    let message_;
    switch (progress) {
        case 'success':
            message_ = message.success({
                key: 1,
                content: `Record ${formType === 'create' ? 'created' : 'updated'}.`,
                duration: 2,
            });
            return message_;
        case 'error':
            if (errorObj) {
                let errorList = [];
                if (typeof errorObj !== 'string') {
                    for (let prop in errorObj) {
                        errorList.push(errorObj[prop].errors ? errorObj[prop].errors : errorObj[prop]);
                    }
                }
                message_ = message.error({
                    key: 1,
                    content: 
                        typeof errorObj === 'string' ? (
                            <h4>Error: {errorObj}</h4>
                        ) : (
                            <>
                            <h4>Errors: </h4>
                            {errorList.map((error, index) => {
                                return (
                                    <p style={{ marginBottom: '5px'}}>
                                        {index + 1}, {error}
                                    </p>
                                )
                            })}
                            </>
                        ),
                    duration: 2,
                });
            } else {
                message_ = message.error({
                    key: 1, 
                    content: `Failed to ${formType === 'create' ? 'create' : 'update'} record.`,
                    duration: 1.5,
                });
            }
            return message_;
        default:
            message_ = message.loading({
                key: 1,
                content: `${formType === 'create' ? 'Creating' : 'Editing'} record...`,
                duration: 1.5,
            });
            return message_;
    }
};

export default SequentialMessageNotifier;
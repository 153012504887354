import React from 'react';
import { Card, Checkbox, Col, Form, Input, Row } from 'antd';
import { inputSize } from '../../Shared/UI/jsx';

const { Item } = Form;
const { TextArea } = Input;

const Guardians = ({ form }) => {
    return (
        <Form
            form={form}
            size="small"
            layout="vertical"
        >
            <Row gutter={[8, 8]}>
                {/* Parent One  */}
                <Card title="Parent One" className='formCard'>
                    <Row>
                        <Col span={8}>
                            <Item name="parentOneFirstName1" label="First Name">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="parentOneFirstName2" label="First Name 2">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="parentOnePreferredName" label="Preferred Name">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="parentOneLastName1" label="Last Name">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="parentOneLastName2" label="Last Name 2">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="parentOneLastNameHyphenated" valuePropName="checked">
                                <Checkbox>
                                    Last Name Hyphenated?
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="parentOneAddress1" label="Address">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="parentOneAddress2" label="Address 2">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="parentOneCity" label="City">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="parentOneState" label="State">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="parentOneZipcode" label="Zipcode">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="parentOnePhone" label="Phone">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="parentOneContact" valuePropName="checked">
                                <Checkbox>
                                    Contact Guardian
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={24}>
                            <Item name="parentOneNotes" label="Notes">
                                <TextArea className='textBox' />
                            </Item>
                        </Col>
                    </Row>
                </Card>
                {/* Parent Two  */}
                <Card title="Parent Two" className='formCard'>
                    <Row>
                        <Col span={8}>
                            <Item name="parentTwoFirstName1" label="First Name">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="parentTwoFirstName2" label="First Name 2">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="parentTwoPreferredName" label="Preferred Name">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="parentTwoLastName1" label="Last Name">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="parentTwoLastName2" label="Last Name 2">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="parentTwoLastNameHyphenated" valuePropName="checked">
                                <Checkbox>
                                    Last Name Hyphenated?
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="parentTwoAddress1" label="Address">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="parentTwoAddress2" label="Address 2">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="parentTwoCity" label="City">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="parentTwoState" label="State">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="parentTwoZipcode" label="Zipcode">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="parentTwoPhone" label="Phone">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="parentTwoContact" valuePropName="checked">
                                <Checkbox>
                                    Contact Guardian
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={24}>
                            <Item name="parentTwoNotes" label="Notes">
                                <TextArea className='textBox' />
                            </Item>
                        </Col>
                    </Row>
                </Card>
                {/* Foster Parent  */}
                <Card title="Foster Parent" className='formCard'>
                    <Row>

                        <Col span={8}>
                            <Item name="fosterParentFirstName1" label="First Name">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="fosterParentFirstName2" label="First Name 2">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="fosterParentPreferredName" label="Preferred Name">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="fosterParentLastName1" label="Last Name">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="fosterParentLastName2" label="Last Name 2">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="fosterParentLastNameHyphenated" valuePropName="checked">
                                <Checkbox>
                                    Last Name Hyphenated?
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="fosterParentAddress1" label="Address">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="fosterParentAddress2" label="Address 2">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="fosterParentCity" label="City">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="fosterParentState" label="State">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="fosterParentZipcode" label="Zipcode">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="fosterParentPhone" label="Phone">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="fosterContact" valuePropName="checked">
                                <Checkbox>
                                    Contact Guardian
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={24}>
                            <Item name="fosterParentNotes" label="Notes">
                                <TextArea className='textBox' />
                            </Item>
                        </Col>
                    </Row>
                </Card>
                {/* Guardian Ad Litum */}
                <Card title="Guardian Ad Litum" className='formCard'>
                    <Row>
                        <Col span={8}>
                            <Item name="guardianAdLitumParentFirstName1" label="First Name">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="guardianAdLitumFirstName2" label="First Name 2">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="guardianAdLitumPreferredName" label="Preferred Name">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="guardianAdLitumLastName1" label="Last Name">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="guardianAdLitumLastName2" label="Last Name 2">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="guardianAdLitumLastNameHyphenated" valuePropName="checked">
                                <Checkbox>
                                    Last Name Hyphenated?
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="guardianAdLitumAddress1" label="Address">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="guardianAdLitumAddress2" label="Address 2">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="guardianAdLitumCity" label="City">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="guardianAdLitumState" label="State">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="guardianAdLitumZipcode" label="Zipcode">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="guardianAdLitumPhone" label="Phone">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="adLitumContact" valuePropName="checked">
                                <Checkbox>
                                    Contact Guardian
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={24}>
                            <Item name="guardianAdLitumNotes" label="Notes">
                                <TextArea className='textBox' />
                            </Item>
                        </Col>
                    </Row>
                </Card>
                {/* Guardian */}
                <Card title="Guardian" className='formCard'>
                    <Row>
                        <Col span={8}>
                            <Item name="guardianFirstName1" label="First Name">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="guardianFirstName2" label="First Name 2">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="guardianPreferredName" label="Preferred Name">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="guardianLastName1" label="Last Name">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="guardianLastName2" label="Last Name 2">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="guardianLastNameHyphenated" valuePropName="checked">
                                <Checkbox>
                                    Last Name Hyphenated?
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="guardianAddress1" label="Address">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="guardianAddress2" label="Address 2">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="guardianCity" label="City">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="guardianState" label="State">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="guardianZipcode" label="Zipcode">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="guardianPhone" label="Phone">
                                <Input style={inputSize} />
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="guardianContact" valuePropName="checked">
                                <Checkbox>
                                    Contact Guardian
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={24}>
                            <Item name="guardianNotes" label="Notes">
                                <TextArea className='textBox' />
                            </Item>
                        </Col>
                    </Row>
                </Card>
            </Row>
        </Form>);
}

export default Guardians;
import React, { lazy, memo } from 'react';
import {  Routes, Route } from 'react-router-dom';
import HomePage from '../../Homepage';
import Clients from '../../Clients';
import Therapists from '../../Therapists';
import ServiceCoordinators from '../../ServiceCoordinator';

const AppRoutes = memo(() => {
    return (
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/clients" element={<Clients />} />
                <Route path="/therapists" element={<Therapists />} />
                <Route path="/servicecoordinators" element={<ServiceCoordinators />} />
            </Routes>
    );
});

export default AppRoutes;
export const iconSize = { fontSize: '20px' };

export const inputSize = { minWidth: '100px', maxWidth: '120px'}

export const dropdownSize = { minWidth: '100px', maxWidth: '150px'}

export const textInputSize = {
    minWidth: '150px',
    maxWidth: '275px',
    minHeight: '100px',
    maxHeight: '100px'
}

import './App.css';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import config from './amplifyconfiguration.json';
import { BrowserRouter } from 'react-router-dom';
import PediPlayLayout from './Shared/UI/PediPlayLayout';

Amplify.configure(config);

const App = ({ signOut, user }) => {
  return (
    <BrowserRouter>
      <PediPlayLayout signOut={signOut} user={user} />
    </BrowserRouter>
  )
}

export default withAuthenticator(App, {hideSignUp: true});

import { Button, Card, Input, Space, Table } from 'antd';
import React, { useRef, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { handleDiscipline } from '../Shared/Functions';

const TherapistTable = ({loading, therapists, highlighted, handleRowOnClick}) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

    const columns = [
        {
            title: "Discipline",
            key: "discipline",
            dataIndex: "discipline",
            ...getColumnSearchProps('discipline'),
            sorter: (a, b) => a.discipline - b.discipline,
            sortDirections: ['descend', 'ascend'],
            render: handleDiscipline,
            width: 150
        },
        {
            title: "Preferred Name",
            key: "preferredName",
            dataIndex: "preferredName",
            ...getColumnSearchProps('preferredName'),
            width: 300
        },
        {
            title: "Last Name",
            key: "lastName",
            dataIndex: "lastName",
            ...getColumnSearchProps('lastName'),
            width: 300
        },
        {
            title: "Address",
            key: "address",
            dataIndex: "address",
            ...getColumnSearchProps('address'),
            width: 500
        },
        {
            title: "City",
            key: "city",
            dataIndex: "city",
            ...getColumnSearchProps('city'),
        },
    ];

    return (
        <Card style={{ height: "100%" }} size="small">
            <Table 
                pagination={false}
                loading={loading}
                dataSource={therapists}
                columns={columns}
                rowKey={(record) => record.id}
                rowClassName={record => (highlighted === record ? 'ant-table-row-selected' : '')}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: () => handleRowOnClick(record)
                    }
                }}
                scroll={{
                    x: 2000,
                    y: 500
                }}
            />
        </Card>
    );
};

export default TherapistTable;
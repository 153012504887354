export const brownOptions = [
    
    {label: '46160', value: '_46160'},
    {label: '46164', value: '_46164'},
    {label: '46181', value: '_46181'},
    {label: '47201', value: '_47201'},
    {label: '47235', value: '_47235'},
    {label: '47274', value: '_47274'},
    {label: '47401', value: '_47401'},
    {label: '47435', value: '_47435'},
    {label: '47448', value: '_47448'},
    {label: '47468', value: '_47468'},
];

export const brownOptionValues = [
    
    '_46160',
    '_46164',
    '_46181',
    '_47201',
    '_47235',
    '_47274',
    '_47401',
    '_47435',
    '_47448',
    '_47468',
];

export const hancockOptions = [
    {label: '46140', value: '_46140'},
    {label: '46064', value: '_46064'},
    {label: '46040', value: '_46040'},
    {label: '46163', value: '_46163'},
    {label: '46055', value: '_46055'},
    {label: '47384', value: '_47384'},
    {label: '46161', value: '_46161'},
    {label: '46130', value: '_46130'},
];

export const hancockOptionValues = [
    '_46140',
    '_46064',
    '_46040',
    '_46163',
    '_46055',
    '_47384',
    '_46161',
    '_46130',
];

export const johnsonOptions = [
    {label: '46106', value: '_46106'},
    {label: '46110', value: '_46110'},
    {label: '46124', value: '_46124'},
    {label: '46131', value: '_46131'},
    {label: '46142', value: '_46142'},
    {label: '46143', value: '_46143'},
    {label: '46151', value: '_46151'},
    {label: '46160', value: '_46160'},
];

export const johnsonOptionValues = [
    '_46106',
    '_46110',
    '_46124',
    '_46131',
    '_46142',
    '_46143',
    '_46151',
    '_46160',
];

export const lawrenceOptions = [
    {label: '47264', value: '_47264'},
    {label: '47436', value: '_47436'},
    {label: '47451', value: '_47451'},
    {label: '47470', value: '_47470'},
    {label: '47420', value: '_47420'},
    {label: '47437', value: '_47437'},
    {label: '47462', value: '_47462'},
    {label: '47421', value: '_47421'},
    {label: '47446', value: '_47446'},
    {label: '47467', value: '_47467'},
];

export const lawrenceOptionValues = [
    '_47264',
    '_47436',
    '_47451',
    '_47470',
    '_47420',
    '_47437',
    '_47462',
    '_47421',
    '_47446',
    '_47467',
];

export const marionOptions = [
    {label: '46077', value: '_46077'},
    {label: '46107', value: '_46107'},
    {label: '46113', value: '_46113'},
    {label: '46163', value: '_46163'},
    {label: '46201', value: '_46201'},
    {label: '46202', value: '_46202'},
    {label: '46203', value: '_46203'},
    {label: '46204', value: '_46204'},
    {label: '46205', value: '_46205'},
    {label: '46208', value: '_46208'},
    {label: '46214', value: '_46214'},
    {label: '46216', value: '_46216'},
    {label: '46217', value: '_46217'},
    {label: '46218', value: '_46218'},
    {label: '46219', value: '_46219'},
    {label: '46220', value: '_46220'},
    {label: '46221', value: '_46221'},
    {label: '46222', value: '_46222'},
    {label: '46224', value: '_46224'},
    {label: '46225', value: '_46225'},
    {label: '46226', value: '_46226'},
    {label: '46227', value: '_46227'},
    {label: '46228', value: '_46228'},
    {label: '46229', value: '_46229'},
    {label: '46231', value: '_46231'},
    {label: '46234', value: '_46234'},
    {label: '46235', value: '_46235'},
    {label: '46236', value: '_46236'},
    {label: '46237', value: '_46237'},
    {label: '46239', value: '_46239'},
    {label: '46240', value: '_46240'},
    {label: '46241', value: '_46241'},
    {label: '46250', value: '_46250'},
    {label: '46206', value: '_46206'},
    {label: '46207', value: '_46207'},
    {label: '46230', value: '_46230'},
    {label: '46242', value: '_46242'},
    {label: '46244', value: '_46244'},
    {label: '46247', value: '_46247'},
    {label: '46251', value: '_46251'},
    {label: '46253', value: '_46253'},
    {label: '46209', value: '_46209'},
    {label: '46213', value: '_46213'},
    {label: '46249', value: '_46249'},
    {label: '46255', value: '_46255'},
    {label: '46262', value: '_46262'},
    {label: '46277', value: '_46277'},
    {label: '46283', value: '_46283'},
    {label: '46285', value: '_46285'},
    {label: '46288', value: '_46288'},
    {label: '46298', value: '_46298'},
];

export const marionOptionValues = [
    '_46077',
    '_46107',
    '_46113',
    '_46163',
    '_46201',
    '_46202',
    '_46203',
    '_46204',
    '_46205',
    '_46208',
    '_46214',
    '_46216',
    '_46217',
    '_46218',
    '_46219',
    '_46220',
    '_46221',
    '_46222',
    '_46224',
    '_46225',
    '_46226',
    '_46227',
    '_46228',
    '_46229',
    '_46231',
    '_46234',
    '_46235',
    '_46236',
    '_46237',
    '_46239',
    '_46240',
    '_46241',
    '_46250',
    '_46206',
    '_46207',
    '_46230',
    '_46242',
    '_46244',
    '_46247',
    '_46251',
    '_46253',
    '_46209',
    '_46213',
    '_46249',
    '_46255',
    '_46262',
    '_46277',
    '_46283',
    '_46285',
    '_46288',
    '_46298',
];

export const monroeOptions = [
    {label: '47401', value: '_47401'},
    {label: '47403', value: '_47403'},
    {label: '47408', value: '_47408'},
    {label: '47404', value: '_47404'},
    {label: '47429', value: '_47429'},
    {label: '47433', value: '_47433'},
    {label: '47405', value: '_47405'},
    {label: '47406', value: '_47406'},
];

export const monroeOptionValues = [
    '_47401',
    '_47403',
    '_47408',
    '_47404',
    '_47429',
    '_47433',
    '_47405',
    '_47406',
];

export const morganOptions = [
    {label: '46106', value: '_46106'},
    {label: '46113', value: '_46113'},
    {label: '46118', value: '_46118'},
    {label: '46120', value: '_46120'},
    {label: '46151', value: '_46151'},
    {label: '46157', value: '_46157'},
    {label: '46158', value: '_46158'},
    {label: '46160', value: '_46160'},
];

export const morganOptionValues = [
    '_46106',
    '_46113',
    '_46118',
    '_46120',
    '_46151',
    '_46157',
    '_46158',
    '_46160',
];


export const shelbyOptions = [
    {label: '46104', value: '_46104'},
    {label: '46110', value: '_46110'},
    {label: '46124', value: '_46124'},
    {label: '46126', value: '_46126'},
    {label: '46130', value: '_46130'},
    {label: '46131', value: '_46131'},
    {label: '46140', value: '_46140'},
    {label: '46150', value: '_46150'},
];

export const shelbyOptionValues = [
    '_46104',
    '_46110',
    '_46124',
    '_46126',
    '_46130',
    '_46131',
    '_46140',
    '_46150',
];
import { handleSPOE } from "../Functions"

export const serviceCoordinatorColumns = [
    {
        title: 'Preferred Name',
        dataIndex: 'preferredName',
        key: 'preferredName'
    },
    {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastNAme'
    },
    {
        title: 'SPOE',
        dataIndex: 'spoe',
        key: 'spoe',
        render: handleSPOE
    }
]

export const therapistColumns = [
    {
        title: 'Preferred Name',
        dataIndex: 'preferredName',
        key: 'preferredName'
    },
    {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName'
    },
    {
        title: 'City',
        dataIndex: 'city',
        key: 'city'
    }
]

export const developmentalTherapistColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address'
    },
]

export const occupationalTherapistColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address'
    },
]

export const physicalTherapistColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address'
    },
]

export const speechTherapistColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address'
    },
]
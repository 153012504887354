import React, { useState, useContext, useEffect, useRef} from 'react';
import { MenuFoldOutlined,
    MenuUnfoldOutlined,
    UserOutlined,
    LogoutOutlined,
    ClockCircleOutlined,
    BuildOutlined,
    BellOutlined } from '@ant-design/icons';
import { Layout, Col, Row, Popover, Button, Divider, Empty, Badge, List, Tag } from 'antd';
import { Link } from 'react-router-dom';

const { Header } = Layout;
const { Item } = List;
const { Meta } = Item;

const PediplayHeader = React.memo(({ setCollapsed, collapsed, signOut}) => {
    const [column, setColumn] = useState(2);
    const [iconColumn, setIconColumn] = useState(4);
    const [username, setUsername] = useState('');
    const logoutTimerIdRef = useRef(null);
    const events = [
        'load',
        'mousemove',
        'mousedown',
        'click',
        'scroll',
        'keypress',
        'touchstart',
        'touchend',
        'wheel'
    ]

    const handleLogoutTimer = () => {
        signOut();
    }

    let mql = window.matchMedia('(max-width: 1024px)');

    const checkScreenSize = () => {
        if(mql.matches) {
            setColumn(7);
            setIconColumn(5);
        } else {
            setColumn(8);
            setIconColumn(4);
        }
    };

    mql.addListener(checkScreenSize);

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    }

    const handleLogout = () => {
        signOut();
    }

    const content = (
        <>
        <Row>
            <Col span={24}>            
                <BuildOutlined style={{ padding: '0 10px'}} />
                <Link to="/changelog">Change Log</Link>
            </Col>
            <Col span={24}>
            <LogoutOutlined style={{ padding: '0 10px'}} />
            <Link onClick={handleLogout}>
                Logout
            </Link>
            </Col>
        </Row>
        </>
    )

    return (
        <Header>
            <Row
                justify="right"
                gutter={[
                    { xs: 4, sm: 8, md: 8, lg: 9},
                    { xs: 4, sm: 8, md: 8, lg: 9}
                ]}
            >
                <Col className='collapseIcon' span={12} style={{ textAlign: 'left'}}>
                    <h1 className='title'>
                    Pediplay
                    </h1>
                </Col>
                <Col offset={6} span={6} style={{ textAlign: 'right' }}>
                    <Popover
                        content={content}
                        placement="bottomRight"
                        trigger="click"
                    >
                        <UserOutlined style={{ fontSize: '16px', padding: '0 10px', color: 'white' }} />
                    </Popover>
                </Col>
            </Row>
        </Header>
    )
});

export default PediplayHeader;
import { Button, Form, Modal, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { reusableSubmitValidator } from '../../Shared/Functions';
import SequentialMessageNotifier from '../../Shared/Functions/SequentialMessageNotifier';
import { CreateServiceCoordinator, EditServiceCoordinator } from '../../Shared/Services';
import ServiceCoordinatorFormContent from './FormContent';

const SCForm = ({
    formType,
    setFormType,
    visibility,
    setVisibility,
    highlighted,
    handleAfterFormSubmit
}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(formType === 'edit') {
            form.setFieldsValue({
                firstName: highlighted.firstName,
                preferredName: highlighted.preferredName,
                lastName: highlighted.lastName,
                spoe: highlighted.spoe.toString(),
                supervisorId: highlighted.supervisorId,
                emailAddress: highlighted.emailAddress,
                phoneNumber: highlighted.phoneNumber,
                buremese: highlighted.buremese,
                chin: highlighted.chin,
                falamChin: highlighted.falamChin,
                hakhaChin: highlighted.hakhaChin,
                matuChin: highlighted.matuChin,
                tedimChin: highlighted.tedimChin,
                zanniatChin: highlighted.zanniatChin,
                zophieChin: highlighted.zophieChin,
                crioulo: highlighted.crioulo,
                filipino: highlighted.filipino,
                french: highlighted.french,
                frenchCajun: highlighted.frenchCajun,
                frenchCanadian: highlighted.frenchCanadian,
                haitainCreole: highlighted.haitainCreole,
                german: highlighted.german,
                punjabi: highlighted.punjabi,
                spanish: highlighted.spanish,
                vietnamese: highlighted.vietnamese,
                somali: highlighted.somali,
                swahili: highlighted.swahili,
                notes: highlighted.notes
            })
        } else {
            form.setFieldsValue({
                supervisorId: null,
                falamChin: false,
                hakhaChin: false,
                matuChin: false,
                tedimChin: false,
                zanniatChin: false,
                zophieChin: false,
                crioulo: false,
                filipino: false,
                french: false,
                frenchCajun: false,
                frenchCanadian: false,
                haitainCreole: false,
                german: false,
                punjabi: false,
                spanish: false,
                vietnamese: false,
                somali: false,
                swahili: false
            })
        }
    }, [visibility]);


    const onClose = () => {
        form.resetFields();
        setVisibility(false);
        setFormType();
    }

    const putRequest = () => {
        form.validateFields().then(values => {
            SequentialMessageNotifier(formType);
            setLoading(true);
            values = {
                ...highlighted,
                ...values,
                ifspDate: values.ifspDate ? values.ifspDate.format('YYYY-MM-DD') : '',
            };
            EditServiceCoordinator(highlighted.id, values)
                .then(res => {
                    if ( res && res.data ) {
                        SequentialMessageNotifier(formType, 'success');
                        handleAfterFormSubmit(res.data);
                        onClose();
                    }
                })
                .catch(error => {
                    if(error.response) {
                        if(error.response.data.ModelState) {
                            let obj = error.response.data.ModelState;
                            SequentialMessageNotifier(formType, 'error', obj);
                        } else if (error.response.data.Message) {
                            SequentialMessageNotifier(formType, 'error', error.response.data.Message);
                        }
                    }
                })
                .finally(() => {
                    setLoading(false)
                });
        });
    };

    const postRequest = () => {
        form.validateFields().then(values => {
            SequentialMessageNotifier(formType);
            setLoading(true);
            values = {
                ...values,
                ifspDate: values.ifspDate ? values.ifspDate.format('YYYY-MM-DD') : '',
            };
            CreateServiceCoordinator(values)
                .then(res => {
                    if ( res && res.data ) {
                        SequentialMessageNotifier(formType, 'success');
                        handleAfterFormSubmit(res.data);
                        onClose();
                    }
                })
                .catch(error => {
                    if(error.response) {
                        if(error.response.data.ModelState) {
                            let obj = error.response.data.ModelState;
                            SequentialMessageNotifier(formType, 'error', obj);
                        } else if (error.response.data.Message) {
                            SequentialMessageNotifier(formType, 'error', error.response.data.Message);
                        }
                    }
                })
                .finally(() => {
                    setLoading(false)
                });
        });
    }

    const onSubmit = () => {
        reusableSubmitValidator(formType, postRequest, putRequest, form);
    }


    return (
        <Modal
            title="Service Coordinator Form"
            onCancel={() => onClose()}
            open={visibility}
            onOk={() => onSubmit()}
            footer={
                <>
                    <Popconfirm title="Are you sure?" placement="bottomRight" onConfirm={() => onClose()}>
                        <Button loading={loading} type="primary" danger>
                            Cancel
                        </Button>
                    </Popconfirm>
                    <Button
                        type="primary"
                        loading={loading}
                        onClick={() => onSubmit()}
                    >
                        Submit
                    </Button>
                </>
            }
        >
            <Form.Provider>
                <div>
                    <ServiceCoordinatorFormContent form={form} formType={formType} highlighted={highlighted} />
                </div>
            </Form.Provider>
        </Modal>
    )
}

export default SCForm;
import React from 'react';
import { RocketOutlined, HomeOutlined, CoffeeOutlined, UserOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { useNavigate } from "react-router-dom";


const SiderMenu = () => {
    let navigate = useNavigate();

    const getItem = (label, key, icon, children, type) => {
      return {
        key,
        icon,
        children,
        label,
        type,
      };
    }
    
    const items = [
      getItem('Home', '1', <HomeOutlined />),
      getItem('Clients', '2', <RocketOutlined />),
      getItem('Therapists', '3', <CoffeeOutlined />),
      getItem('Service Coordinators', '4', <UserOutlined />),
    ];
    
    const onClick = (e) => {
      switch (e.key) {
        case '1':
            navigate('/');
            break;
        case '2':
            navigate('/clients');
            break;
        case '3':
            navigate('/therapists');
            break;
        case '4':
            navigate('/servicecoordinators');
            break;
      }
    };

    return(
        <Menu
          onClick={onClick}
          style={{
            top: '35px',
            width: 256,
          }}
          mode="vertical"
          items={items}
        />
    )
};
export default SiderMenu;
import React from 'react';
import { Card, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { dropdownSize, inputSize, textInputSize } from '../../../Shared/UI/jsx';

const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;

const PersonalAndAddress = ({ form, formType }) => {

    return(
        <Card size="small" className='formCard'>
            <Form
                form={form}
                size="small"
                layout="vertical"
            >
                <Row>
                    {formType === 'edit' && 
                    <>
                        <Col span={8}>
                            <Item name="active" valuePropName="checked">
                                <Checkbox>
                                    Active?
                                </Checkbox>
                            </Item>
                        </Col>
                        <Col span={8}>
                            <Item name="otherAgency" valuePropName="checked">
                                <Checkbox>
                                    Other Agency?
                                </Checkbox>
                            </Item>
                        </Col>
                    </>                    
                    }
                    <Col span={8}>
                        <Item name="firstName" label="First Name">
                            <Input style={inputSize}/>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="preferredName" label="Preferred Name">
                            <Input style={inputSize}/>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="lastName" label="Last Name">
                            <Input style={inputSize}/>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="discipline" label="Discipline">
                         <Select 
                             style={dropdownSize}
                             showSearch
                             filterOption={(input, option) => 
                                 option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                             }
                         >
                             <Option value="0">None</Option>
                             <Option value="1">Physical Therapist</Option>
                             <Option value="2">Physical Therapist Assistant</Option>
                             <Option value="3">Occupational Therapist</Option>
                             <Option value="4">Certified Occupational Therapy Assistant</Option>
                             <Option value="5">Developmental Therapist</Option>
                             <Option value="6">Developmental Therapy Assistant</Option>
                             <Option value="7">Speech-Language Pathologist</Option>
                             <Option value="8">Speech-Language Pathologist Clinical Fellowship</Option>
                             <Option value="9">Registered Nurse</Option>
                             <Option value="10">Social Worker</Option>
                             <Option value="11">Behavioralist</Option>
                             <Option value="12">Psychologist</Option>
                             <Option value="13">Student</Option>
                         </Select>
                        </Item>
                    </Col>
                    {/* <Col span={8}>
                        <Item name="spoe" label="SPOE">
                         <Select 
                             style={inputSize}
                             showSearch
                             filterOption={(input, option) => 
                                 option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                             }
                         >
                             <Option value="0">J</Option>
                             <Option value="1">G</Option>
                             <Option value="2">All</Option>
                         </Select>
                        </Item>
                    </Col> */}
                    <Col span={8}>
                        <Item name="emailAddress" label="Email Address">
                            <Input style={inputSize}/>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="phoneNumber" label="Phone Number">
                            <Input style={inputSize}/>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="address" label="Address">
                            <Input style={inputSize}/>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="address2" label="Address 2">
                            <Input style={inputSize}/>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="city" label="City">
                            <Input style={inputSize}/>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="state" label="State">
                            <Input style={inputSize}/>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="zipcode" label="Zipcode">
                            <Input style={inputSize}/>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="country" label="Country">
                            <Input style={inputSize}/>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="pictureLink" label="Picture Link">
                            <Input style={inputSize}/>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="comments" label="Comments">
                            <TextArea style={textInputSize}/>
                        </Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
}

export default PersonalAndAddress;
import React from 'react';
import { Card, Checkbox, Col, Form, Input, Row } from 'antd';
import { inputSize } from '../../../Shared/UI/jsx';

const { Item } = Form;

const Specialities = ({ form }) => {

    return(
            <Form
                form={form}
                size="small"
                layout="vertical"
            >
                <Row gutter={[8,8]}>
                    <Card size='small' title="Feeding" className='formCard'>
                        <Row>

                    <Col span={8}>
                        <Item name="oralMotorFeeding" valuePropName="checked">
                            <Checkbox>
                                Oral Motor Feeding
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="sensoryBasedFeeding" valuePropName="checked">
                            <Checkbox>
                                Sensory Based Feeding
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="gTubeToOral" valuePropName="checked">
                            <Checkbox>
                                G Tube to Oral
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="foodAversion" valuePropName="checked">
                            <Checkbox>
                                Food Aversion
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="motorTransitSwallowingDysfunction" valuePropName="checked">
                            <Checkbox>
                                Motor Transit Swallowing Dysfunction?
                            </Checkbox>
                        </Item>
                    </Col>
                        </Row>
                    </Card>
                    <Card title="Behavior" size='small' className='formCard'>
                        <Row>
                    <Col span={8}>
                        <Item name="behavior" valuePropName="checked">
                            <Checkbox>
                                Behavior
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="specifyBehavior" label="Specify Behavior">
                            <Input style={inputSize}/>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="aBACert" valuePropName="checked">
                            <Checkbox>
                                ABA Certificate
                            </Checkbox>
                        </Item>
                    </Col>
                        </Row>
                    </Card>
                    <Card title="Sign Language" size='small' className='formCard'>
                        <Row>
                    <Col span={8}>
                        <Item name="als" valuePropName="checked">
                            <Checkbox>
                                ALS
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="signingExactEnglish" valuePropName="checked">
                            <Checkbox>
                                Signing Exact English
                            </Checkbox>
                        </Item>
                    </Col>
                        </Row>
                    </Card>
                    <Card size='small' title="Equipment" className='formCard'>
                        <Row>

                    <Col span={8}>
                        <Item name="handlingNDT" valuePropName="checked">
                            <Checkbox>
                                Handling NDT
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="gTubeComfortable" valuePropName="checked">
                            <Checkbox>
                                G Tube Comfortable
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="ventComfortable" valuePropName="checked">
                            <Checkbox>
                                Vent Comfortable
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="passyMuirValve" valuePropName="checked">
                            <Checkbox>
                                Passy Muir Valve
                            </Checkbox>
                        </Item>
                    </Col>
                        </Row>
                    </Card>
                    <Card size='small' title="Certifications" className='formCard'>
                        <Row>
                    <Col span={8}>
                        <Item name="floortime" valuePropName="checked">
                            <Checkbox>
                                Floortime
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="infantMentalHealthCert" valuePropName="checked">
                            <Checkbox>
                                Infant Mental Health Certificate
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="fGRBICohort" valuePropName="checked">
                            <Checkbox>
                                FRGBI Cohort
                            </Checkbox>
                        </Item>
                    </Col>
                        </Row>
                    </Card>
                    <Card title="Assitive Technology" size='small' className='formCard'>
                        <Row>
                    <Col span={8}>
                        <Item name="kinesiotapingExp" valuePropName="checked">
                            <Checkbox>
                                Kinesiotaping Experience
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="kinesiotapingCert" valuePropName="checked">
                            <Checkbox>
                                Kinesiotaping Certification
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="aACComfort" valuePropName="checked">
                            <Checkbox>
                                AAC Comfort
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="aACExperience" valuePropName="checked">
                            <Checkbox>
                                AAC Experience
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="aACCertification" valuePropName="checked">
                            <Checkbox>
                                AAC Certification
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="visualScheduleExperience" valuePropName="checked">
                            <Checkbox>
                                Visual Schedule Experience
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="pECS" valuePropName="checked">
                            <Checkbox>
                                PECS
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="hannen" valuePropName="checked">
                            <Checkbox>
                                Hannen
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="assistativeTechnology" valuePropName="checked">
                            <Checkbox>
                                Assisstative Technology
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="orthoticsUse" valuePropName="checked">
                            <Checkbox>
                                Orthotics Use
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="orthoticsAdjustment" valuePropName="checked">
                            <Checkbox>
                                Orthotics Adjustment
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="wheelchairSupport" valuePropName="checked">
                            <Checkbox>
                                Wheelchair Support
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="wheelchairTraining" valuePropName="checked">
                            <Checkbox>
                                Wheelchair Training
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="wheelchairFitting" valuePropName="checked">
                            <Checkbox>
                                Wheelchair Fitting
                            </Checkbox>
                        </Item>
                    </Col>
                        </Row>
                    </Card>
                    <Card title="Alternative Therapies" size='small' className='formCard'>
                        <Row>
                    <Col span={8}>
                        <Item name="aquaticTherapy" valuePropName="checked">
                            <Checkbox>
                                Aquatic Therapy
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="hippoTherapy" valuePropName="checked">
                            <Checkbox>
                                Hippo Therapy
                            </Checkbox>
                        </Item>
                    </Col>
                        </Row>
                    </Card>
                    <Card size='small' title='Experience' className='formCard'>
                        <Row>
                    <Col span={8}>
                        <Item name="autismExperience" valuePropName="checked">
                            <Checkbox>
                                Autism Experience
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="splinting" valuePropName="checked">
                            <Checkbox>
                                Splinting
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="weightedVestFitting" valuePropName="checked">
                            <Checkbox>
                                Weighted Vest Fitting
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="primitiveReflexIntegration" valuePropName="checked">
                            <Checkbox>
                                Primitive Reflex Integration
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="additionalExpertise" valuePropName="checked">
                            <Checkbox>
                                Additional Expertise
                            </Checkbox>
                        </Item>
                    </Col>
                    <Col span={8}>
                        <Item name="additionalExpertiseMemo" label="Additional Expertise Memo">
                            <Input style={inputSize}/>
                        </Item>
                    </Col>
                        </Row>
                    </Card>
                </Row>
            </Form>
    );
}

export default Specialities;